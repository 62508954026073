import React, { useState } from "react";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import { ModalBody, ModalFooter, SimpleModal } from "core/components/modal/SimpleModal";
import Button from "core/components/Button";
import { FormInput } from "core/components";
import Service from "entities/service";

const ServicePayment = ({ service }) => {
    const { id, amount } = service;
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    const [saving, setSaving] = useState(false);
    const [collectedAmount, setCollectedAmount] = useState(amount);
    return (
        <>
            <Button
                iconName="wallet"
                label="Cobrar"
                type="button"
                onClick={() => setShowModal(true)}
                className="btn-primary mr-2"
            />
            <SimpleModal show={showModal} size="sm">
                <ModalBody>
                    <FormInput
                        label="Monto"
                        value={collectedAmount}
                        onChange={e => setCollectedAmount(parseInt(e.target.value || 0))}
                        type="number"
                    />
                </ModalBody>
                <ModalFooter>
                    <Button className="btn btn-secondary" onClick={() => setShowModal(false)} label="Cancelar" />
                    <Button
                        className="btn btn-primary"
                        onClick={async () => {
                            setSaving(true);
                            try {
                                await Service.collectPayment(id, { amount: collectedAmount });
                                history.go(0);
                            }
                            catch {
                                setSaving(false);
                            }
                        }}
                        label="Guardar"
                        showSpinner={saving}
                    />
                </ModalFooter>
            </SimpleModal>
        </>
    );
};

ServicePayment.propTypes = {
    service: PropTypes.any.isRequired
};

export default ServicePayment;
import React from "react";
import ListViewRowWrapper from "core/components/ListViewRowWrapper";
import Row from "components/services/ServiceTableRow";
import PropTypes from "prop-types";
import Button from "core/components/Button";
import { Parser } from "@json2csv/plainjs";
import { downloadWithFallback } from "core/util/fileUtilities";
import { formatDate, areDatesEqual } from "core/util/dateUtilities";
import Statement from "entities/statement";

const parser = new Parser();

const Table = ({ services, loading, onBottomScroll, addNewStatement }) => {
    const rows = services.map((x, index) => <Row service={x} key={index} addNewStatement={addNewStatement} />);
    const columns = ["Quién?", "Fecha Desde", "Monto", "Minutos Disponibles", "Concepto", "Comprobantes", "Acciones"];
    return (
        <div style={{ position: "relative" }}>
            <div style={{ position: "absolute", right: 0, top: -45 }}>
                <Button
                    iconName="spreadsheet"
                    label="Export to CSV"
                    type="button"
                    onClick={() => {
                        const csvRows = services.map(x => ({
                            "Quién?": x.customerName,
                            "Fecha Desde": formatDate(x.startDate),
                            "Fecha Hasta": areDatesEqual(x.startDate, x.endDate) ? "n/a" : formatDate(x.endDate),
                            "Monto": x.amount,
                            "Concepto": x.description,
                            "Comprobantes": x.statements.map(y => Statement.getLabel(y)).join(", ")
                        }));
                        const csv = parser.parse(csvRows, { withBOM: true });
                        const blob = new Blob([csv], { type: "text/csv" });
                        downloadWithFallback(blob, "colaboro-services.csv");
                    }}
                    className="btn-primary"
                />
            </div>
            <table className="table table-hover">
                <thead><tr>{columns.map((x, idx) => <th key={idx}>{x}</th>)}</tr></thead>
                <tbody>
                    <ListViewRowWrapper
                        rows={rows}
                        numberOfColumns={columns.length}
                        loading={loading}
                        onBottomScroll={onBottomScroll}
                    />
                </tbody>
            </table>
        </div>
    );
};

Table.propTypes = {
    services: PropTypes.arrayOf(PropTypes.any).isRequired,
    loading: PropTypes.bool.isRequired,
    onBottomScroll: PropTypes.func.isRequired,
    addNewStatement: PropTypes.func.isRequired
};

export default Table;

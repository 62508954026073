import ListViewRowWrapper from "core/components/ListViewRowWrapper";
import Row from "components/unlocks/UnlockTableRow";
import PropTypes from "prop-types";
import React from "react";

const Table = ({ unlocks, loading, onBottomScroll }) => {
    const rows = unlocks.map((x, index) => (
        <Row unlock={x} key={index} />
    ));
    const columns = ["Quién?", "Cliente", "Fecha"];
    return (
        <table className="table table-hover">
            <thead><tr>{columns.map((x, idx) => <th key={idx}>{x}</th>)}</tr></thead>
            <tbody>
                <ListViewRowWrapper
                    rows={rows}
                    numberOfColumns={columns.length}
                    loading={loading}
                    onBottomScroll={onBottomScroll}
                />
            </tbody>
        </table>
    );
};

Table.propTypes = {
    unlocks: PropTypes.arrayOf(PropTypes.any).isRequired,
    loading: PropTypes.bool.isRequired,
    onBottomScroll: PropTypes.func.isRequired
};

export default Table;

import apiFetch from "core/fetch/apiFetch";
import UnlockFilter from "./unlockFilter";

const _baseEndPoint = "unlocks";

export interface UnlockViewModel {
    memberName: string;
    customerName: string;
    date: Date;
}

export default class Unlock {
    static async search(filter = new UnlockFilter(), page: number): Promise<UnlockViewModel[]> {
        filter.setPaginationParameters(page);
        return await apiFetch(`${_baseEndPoint}?${filter.toQueryString()}`);
    }
}

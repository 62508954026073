import apiFetch from "core/fetch/apiFetch";
import ServiceFilter from "./serviceFilter";
import { StatementViewModel } from "./statement";

const _baseEndPoint = "services";

interface ServiceInput {
    customerId: number | null;
    amount: number;
    description: string;
    startDate: Date | null;
    endDate: Date | null;
}

interface ServiceAdjustmentInput {
    adjustedAmount: number;
    addedMinutes: number;
}

interface ServicePaymentInput {
    amount: number;
}

export interface ServiceViewModel {
    id: number;
    customerName: string;
    startDate: Date;
    endDate: Date;
    amount: number;
    description: string;
    availableCreditMinutes?: number;
    statements: StatementViewModel[];
}

export default class Service {
    static getDefaultInput(): ServiceInput {
        return ({
            customerId: null,
            amount: 0,
            description: "",
            startDate: null,
            endDate: null
        });
    }
    static async update(serviceId: number, input: ServiceInput): Promise<ServiceViewModel> {
        return apiFetch(`${_baseEndPoint}/${serviceId}`, "PUT", input);
    }
    static async search(filter = new ServiceFilter(), page: number): Promise<ServiceViewModel[]> {
        filter.setPaginationParameters(page);
        return await apiFetch(`${_baseEndPoint}?${filter.toQueryString()}`);
    }
    static async getById(id: number): Promise<ServiceInput> {
        return await apiFetch(`${_baseEndPoint}/${id}`);
    }
    static create(item: ServiceInput): Promise<ServiceViewModel> {
        return apiFetch(_baseEndPoint, "POST", item);
    }
    static async adjust(serviceId: number, adjustments: ServiceAdjustmentInput): Promise<ServiceViewModel> {
        return apiFetch(`${_baseEndPoint}/${serviceId}/adjust`, "PUT", adjustments);
    }
    static async generateInvoice(serviceId: number): Promise<StatementViewModel> {
        return apiFetch(`${_baseEndPoint}/${serviceId}/invoices`, "POST");
    }
    static async collectPayment(serviceId: number, details: ServicePaymentInput) {
        apiFetch(`${_baseEndPoint}/${serviceId}/payments`, "POST", details);
    }
}
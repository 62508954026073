import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { getLoginData } from "core/storage/localStorage";
import { formatDate } from "core/util/dateUtilities";
import FAQs from "components/FAQs";

const DashboardPage = () => {
  const { plan } = getLoginData();
  const startOfNextMonth = moment().add(1, "M").startOf("month");
  return (
    <div className="container-fluid container-fluid-spacious">
      <div className="container pt-3">
        <div className="row card-deck">
          <div className="card mb-4 box-shadow p-3">
            Bienvenido al portal de miembros de Colaboro (v{process.env.REACT_APP_CLIENT_VERSION})
            {plan &&
              <p className="mt-2">
                <strong>Membresía:</strong> {plan.name}
                <br />
                <strong>Fecha próxima factura:</strong> {formatDate(startOfNextMonth)} ({plan.price} ARS)
              </p>}
          </div>
          <div className="card mb-4 box-shadow p-3">
            <div style={{ textAlign: "center" }}>
              <Link to="/bookings/create" className="btn btn-primary" style={{ width: 200 }}>
                <i className="fa fa-plus" style={{ marginRight: 5 }} /> Nueva reserva
              </Link>
            </div>
          </div>
        </div>
        <div className="row card-deck">
          <div className="card mb-4 box-shadow p-3">
            <FAQs />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
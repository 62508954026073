import React from "react";
import PropTypes from "prop-types";
import { formatDateWithTime } from "core/util/dateUtilities";

const Row = ({ unlock }) => {
    const { memberName, customerName, date } = unlock;
    return (
        <tr>
            <td>{memberName}</td>
            <td>{customerName}</td>
            <td>{formatDateWithTime(date)}</td>
        </tr>
    );
};

Row.propTypes = {
    unlock: PropTypes.any.isRequired
};

export default Row;
